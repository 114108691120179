import { Component } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AnalyzerModalComponent } from '../analyzer-modal/analyzer-modal.component';
import { AlertService } from '../../services/alert.service';
import { AnalysisService } from '../../services/analysis.service';
import { UtilService } from '../../modules/shared/services/util.service';

@Component({
  selector: 'app-analyzer',
  templateUrl: './analyzer.component.html',
  styleUrls: ['./analyzer.component.scss']
})
export class AnalyzerComponent {

  constructor(private modalService: BsModalService,
              private analysisService: AnalysisService,
              private alertService: AlertService,
              private utilService: UtilService) { }

  files: File[] = [];

  readonly acceptedMimeTypes = 'application/pdf,' +
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,' +
    'application/vnd.ms-excel.sheet.macroEnabled.12';

  readonly maxFileSize = 6000000; // 6MB

  done = false;
  jobId: string;
  resultAnalysisFilename: string;
  resultTransformedDocumentsFilename: string;
  isDownloadingResultAnalysisFile: boolean;
  isDownloadingTransformedDocumentsFile: boolean;

  onDrop(event): void {
    this.files.push(...event.addedFiles);

    // Clear results if new file was dropped
    if (this.done) {
      this.clearResults();
    }
  }

  onRemove(index: number): void {
    this.files.splice(index, 1);
  }

  startAnalysis(): void {
    this.determineResultFilenames();

    const modalRef = this.modalService.show(AnalyzerModalComponent, {
      class: 'modal-dialog-centered',
      ignoreBackdropClick: true,
      keyboard: false,
      initialState: { files: this.files }
    });
    const subscription = this.modalService.onHide.subscribe(() => {
      if (modalRef.content.analysisStatusResponse?.done) {
        this.done = true;
        this.isDownloadingResultAnalysisFile = false;
        this.jobId = modalRef.content.startAnalysisResponse?.jobId;
      }
      subscription.unsubscribe();
    });
  }

  determineResultFilenames():void {
    let firstExcelFile = this.files.find(file => file.name.toLowerCase().endsWith(".xlsx") || file.name.toLowerCase().endsWith(".xlsm"));
    let resultFileExtension = firstExcelFile ? firstExcelFile.name.split('.').pop().toLowerCase() : "xlsx";
    this.resultAnalysisFilename = 'Result file.' + resultFileExtension;

    this.resultTransformedDocumentsFilename = 'Transformed documents.zip';
  }

  downloadAnalysisResultFile(): void {
    this.isDownloadingResultAnalysisFile = true;

    this.analysisService.getAnalysisResult(this.jobId).subscribe(analysisResultResponse => {
      if (analysisResultResponse.errorMessage != null) {
        this.alertService.error(analysisResultResponse.errorMessage);
      }

      if (analysisResultResponse.file != null) {
        this.utilService.downloadFileFromBase64String(analysisResultResponse.file, this.resultAnalysisFilename.split('.').pop(), this.resultAnalysisFilename);
      }
    }).add(() => this.isDownloadingResultAnalysisFile = false);
  }

  downloadTransformedDocumentsFile(): void {
    this.isDownloadingTransformedDocumentsFile = true;

    this.analysisService.getAnalysisTransformedDocuments(this.jobId).subscribe(transformedDocumentsResponse => {
      if (transformedDocumentsResponse.file != null) {
        this.utilService.downloadFileFromBase64String(transformedDocumentsResponse.file, this.resultTransformedDocumentsFilename.split('.').pop(), this.resultTransformedDocumentsFilename);
      }
    }).add(() => this.isDownloadingTransformedDocumentsFile = false);
  }

  clearResults(): void {
    this.done = false;
    this.jobId = null;
  }

  startNewCalculation(): void {
    this.files = [];
    this.clearResults();
  }
}
