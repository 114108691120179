<div class="container">

    <h1>KAMAX CSS Styleguide</h1>

    <h1>H1</h1>
    <h2>H2</h2>
    <h3>H3</h3>
    <h4>H4</h4>
    <h5>H5</h5>
    <h6>H6</h6>

    <h6 class="mt-5">Primary button</h6>
            <button class="btn btn-primary">Button 1</button>

    <h6 class="mt-5">Secondary button</h6>
            <button class="btn btn-secondary">Button 2</button>

    <h6 class="mt-5">Danger button</h6>
            <button class="btn btn-danger">Button 3</button>

    <h6 class="mt-5">Checkbox</h6>
        <label class="checkbox-wrapper">
            <input type="checkbox"/>
            <span class="checkbox"></span>
        </label>

    <h6 class="mt-5">Radio button</h6>
        <label class="container radio-wrapper m-1">
            <input type="radio" id="1" name="example" value="1">
            <span class="radio-button"></span>
        </label>
        <label class="container radio-wrapper m-1">
            <input type="radio" id="2" name="example" value="2">
            <span class="radio-button"></span>
        </label>

    <h6 class="mt-5">Toggle button</h6>
        <label class="switch">
            <input type="checkbox"/>
            <span class="slider"></span>
        </label>

    <h6 class="mt-5">Text input / form</h6>
        <div class="form-group has-feedback">
            <input type="text" class="form-control" placeholder="placeholder" required>
            <span class="form-control-feedback"></span>
        </div>

    <h6 class="mt-5">Text area</h6>
        <div class="form-group has-feedback">
            <textarea class="form-control" id="exampleTextArea" rows="5"></textarea>
        </div>

    <h6 class="mt-5">ng-select</h6>
        <ng-select
            class="dialog-select"
            style="width: 220px"
            [multiple]="false"
            [placeholder]="'placeholder' | translate"
            [clearable]="false">
            <ng-option>
                    option 1
            </ng-option>
            <ng-option>
                    option 2
            </ng-option>
        </ng-select>

    <h6 class="mt-5">Dropdown</h6>

        <div class="dropdown">  
            Dropdown-Toggle:
            <img
                src="assets/icons/more_horiz-24px.svg"
                data-toggle="dropdown" 
            >
            <div class="dropdown-menu dropdown-menu-right">
                <a class="dropdown-item" >option 1</a>
                <a class="dropdown-item" >option 2</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item disabled" translate>disabled</a>   
            </div>
        </div>

    <h6 class="mt-5">Pagination</h6>

    <ul>
        <li *ngFor="let item of examplePaginationList | paginate: { itemsPerPage: 1, currentPage: 2 }" class="d-none"> ... </li>
    </ul>
    <pagination-controls class="pagination" autoHide="true" previousLabel="" nextLabel=""></pagination-controls>   

    <h6 class="mt-5">Breadcrumbs</h6>
        <ul class="breadcrumb">
            <li>page 1</li>
            <li>page 2</li>
            <li>page 3</li>
        </ul>

    <h6 class="mt-5">Tags</h6>
        <label class="tag enabled mr-2">Enabled</label>
        <label class="tag selected">Selected</label>

</div>
