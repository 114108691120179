import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Endpoints } from '../../../endpoints';
import { AuthorizedService } from './authorized-service';
import { map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class SupportedLanguagesService extends AuthorizedService {
  private allSupportedLanguages$: Observable<string[]>;

  constructor(private http: HttpClient) {
    super();
  }

  getAllSupportedLanguages(): Observable<string[]> {
    if (!this.allSupportedLanguages$) {
      this.allSupportedLanguages$ = this.http.get<{ supportedLanguages: string[] }>(Endpoints.getAllSupportedLanguages(),
        { headers: this.authorizationHeader() }).pipe(
          shareReplay(1),
          map(dto => dto.supportedLanguages as string[])
      );
    }
    return this.allSupportedLanguages$;
  }
}
