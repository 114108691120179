import { CanActivate } from '@angular/router';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(
      private router: Router,
      private authService: AuthService
    ) { }

    canActivate(activatedRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.authService.isLoggedIn()) {
          // User is logged in

          let accessDenied = false;
          
          // Checks requirement for any role
          const requiresAnyRole = activatedRoute.data['requiresAnyRole'] as Array<string>;
          if (requiresAnyRole) {
            if (!this.authService.userHasAnyRole(requiresAnyRole)) {
              accessDenied = true;
            }
          }
          
          // Checks requirement for one role
          const requiresRole = activatedRoute.data['requiresRole'] as string;
          if (requiresRole) {
            if (!this.authService.userHasRole(requiresRole)) {
              accessDenied = true;
            }
          }

          if(accessDenied) {
            this.router.navigate(['/access-denied']);
            return false;
          }

          return true;
        } else {
          // Token not available. Redirect to login page.
          const redirectUrl = activatedRoute['_routerState']['url'];
          this.router.navigateByUrl(
            this.router.createUrlTree(
              ['/login'], {
                queryParams: {
                  redirectUrl
                }
              }
            )
          );
          return false;
        }
    }
}
