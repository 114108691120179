import { HttpHeaders } from '@angular/common/http';
import { Injectable } from "@angular/core";

@Injectable()
export class AuthorizedService {

    /**
     * Returns authorization header using token.
     */
    protected authorizationHeader(): HttpHeaders {
        const token = localStorage.getItem('bearerToken');
        if (token) {
            return new HttpHeaders( { 'Authorization': 'Bearer ' + token });
        }
        return new HttpHeaders( {} );
    }
}
