import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  AnalysisResultResponseDto,
  AnalysisStatusResponseDto,
  AnalysisTransformedDocumentsResponseDto,
  StartAnalysisRequestDto,
  StartAnalysisResponseDto
} from '../models/analysis';
import { AuthorizedService } from '../modules/shared/services/authorized-service';
import { Endpoints } from '../endpoints';

@Injectable({
  providedIn: 'root'
})
export class AnalysisService extends AuthorizedService {

  constructor(private http: HttpClient) {
    super();
  }

  startAnalysis(startAnalysisRequest: StartAnalysisRequestDto): Observable<StartAnalysisResponseDto> {
    return this.http.post<StartAnalysisResponseDto>(Endpoints.analysis(), startAnalysisRequest, { headers: this.authorizationHeader() });
  }

  getAnalysisStatus(jobId: string): Observable<AnalysisStatusResponseDto> {
    const params = new HttpParams().set('jobId', jobId);
    return this.http.get<AnalysisStatusResponseDto>(Endpoints.analysisStatus(), { params, headers: this.authorizationHeader() });
  }

  getAnalysisResult(jobId: string): Observable<AnalysisResultResponseDto> {
    const params = new HttpParams().set('jobId', jobId);
    return this.http.get<AnalysisResultResponseDto>(Endpoints.analysisResult(), { params, headers: this.authorizationHeader() });
  }

  getAnalysisTransformedDocuments(jobId: string): Observable<AnalysisTransformedDocumentsResponseDto> {
    const params = new HttpParams().set('jobId', jobId);
    return this.http.get<AnalysisResultResponseDto>(Endpoints.analysisTransformedDocuments(), { params, headers: this.authorizationHeader() });
  }
}
