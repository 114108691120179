import { environment } from '../environments/environment';

export class Endpoints {

  // Auth
  public static login(): string { return environment.backendUrl + '/auth/token?grant_type=password'; }
  public static logout(): string { return environment.backendUrl + '/auth/revoke'; }

  // Localization
  public static localizedString(languageCode: string): string { return environment.backendUrl + '/localization/language/' + languageCode; }
  public static getAllSupportedLanguages(): string { return environment.backendUrl + '/localization/supported-languages'; }

  // Analysis
  public static analysis(): string { return environment.backendUrl + '/api/analysis'; }
  public static analysisStatus(): string { return environment.backendUrl + '/api/analysis/status'; }
  public static analysisResult(): string { return environment.backendUrl + '/api/analysis/result/analysis'; }
  public static analysisTransformedDocuments(): string { return environment.backendUrl + '/api/analysis/result/transformed-documents'; }

}
