<div class="container-fluid main-wrapper">
  <div class="row h-100">
    <div class="col-sm-7 d-none d-lg-block p-0 bg">
      <img src="assets/nexineer-logo-white.svg" alt="nexineer" width="282" height="100" id="nexineer-logo" />
    </div>
    <div class="col-lg-5 bg-white">

      <div class="row logo-row">
        <img src="assets/kamax_logo.svg" class="m-auto kx-logo">
      </div>

      <div class="row welcome-text-row">
        <div class="container welcome-text text-center">
        <h4 class="m-auto">Automated Price Adaption</h4>
        </div>
      </div>

      <div class="row login-row">
        <div class="login-wrapper">
          <form id="login-form" [formGroup]="loginForm" (ngSubmit)="onLogin()">
            <div class="card">
              <div class="card-body p-0">
                <div class="w-75 m-auto">
                  <apa-alert></apa-alert>
                </div>
                <div class="form-group has-feedback mb-3">
                  <input #emailInput type="email" class="form-control form-control-lg w-75 m-auto" [ngClass]="{'validated': isValidated}"[placeholder]="'placeholder_email' | translate" formControlName="email" required >
                  <span class="form-control-feedback"></span>
                </div>
                <div class="form-group has-feedback mb-0">
                  <input #passwordInput type="password" class="form-control form-control-lg w-75 m-auto" [ngClass]="{'validated': isValidated}" [placeholder]="'placeholder_password' | translate" formControlName="password" required >
                  <span class="form-control-feedback"></span>
                </div>
                <div class="form-group">
                  <div class="w-75 m-auto">
                    <button id="login-button" type="submit" class="btn btn-primary btn-block w-100" [disabled]="emailInput.value == '' || passwordInput.value == ''" translate>button_login</button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <!--TODO: Set links to sites-->
      <div class="row footer-text-row">
        <div class="d-flex container footer-text align-items-center">
          <span class="m-auto">
          <span class="caption" translate>footer_conditions</span>
          <span class="caption p-1">|</span>
          <span class="caption" translate>footer_data_protection</span>
          <span class="caption p-1">|</span>
          <span class="caption" translate>footer_company_details</span>
          <span class="caption p-1">|</span>
          <span class="caption">Kamax</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
