import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { AuthorizedService } from '../modules/shared/services/authorized-service';
import { Endpoints } from 'src/app/endpoints';
import { AlertService } from './alert.service';

@Injectable()
export class AuthService extends AuthorizedService {

  private loginStatus$ = new Subject<boolean>();

  constructor(private http: HttpClient, private router: Router, private alertService: AlertService) {
    super();
  }

  /**
   * Logs in user by username and password
   * @param username    username
   * @param password    password
   */
  login(username: string, password: string): Observable<any> {
    const body = 'username=' + encodeURIComponent(username) + '&password=' + encodeURIComponent(password);
    const options = {
      headers: new HttpHeaders({
        'Authorization': 'Basic ' + btoa('apaserver-client:apaserver-secret'),
        'Content-Type': 'application/x-www-form-urlencoded'
      }),
      withCredentials: true
    };

    return this.http.post<any>(Endpoints.login(), body, options).pipe(map(data => {
      if (data && data.access_token) {
        localStorage.setItem('bearerToken', data.access_token);
        localStorage.setItem('username', username);
        localStorage.setItem('roles', JSON.stringify(data.roles));
        this.loginStatus$.next(true);
      } else {
        this.deleteTokens();
      }

      return data;
    }));
  }

  /**
   * Logs out current user.
   */
  logout(): void {
    if (localStorage.getItem('bearerToken') === null) {
      console.error('Cannot logout because no token exists.');
      this.loginStatus$.next(false);
    } else {
      this.http.post(Endpoints.logout(), '', {headers: this.authorizationHeader()})
        .subscribe(
          res => {
            this.deleteTokens();
            this.router.navigate(['/login']).then(() => {
              this.alertService.info("Logout successful.");
            });

          }, error => { // Handels logout button click when token is invalid or backend server unavailable
            this.deleteTokens();
            this.router.navigate(['/login']).then(() => {
              this.alertService.info("Logout successful.");
            });
          }
        );
    }
  }

  /**
   * Deletes authorisation token, username and roles from local storage.
   */
  deleteTokens() {
    localStorage.removeItem('bearerToken');
    localStorage.removeItem('username');
    localStorage.removeItem('roles');

    this.loginStatus$.next(false);
  }

  /**
   * Checks if authorisation token exists in local storage.
   */
  isLoggedIn(): boolean {
    return (localStorage.getItem('bearerToken') !== null);
  }

  /**
   * Returns subscribable login status.
   */
  observeLoggedIn(): Observable<boolean> {
    return this.loginStatus$.asObservable();
  }

  /**
   * Returns string array of user roles from current user.
   */
  getUserRoles(): string[] {
    return JSON.parse(localStorage.getItem('roles')) as string[];
  }

  /**
   * Returns if user has any of the given roles.
   * @param roles roles
   */
  userHasAnyRole(roles: string[]) {
    // User must be logged in
    if (this.isLoggedIn()) {
      // Check all roles
      const userRoles = this.getUserRoles();
      for (let i = 0; i < roles.length; i++) {
        if (userRoles.includes(roles[i])) {
          return true;
        }
      }
    }
    return false;
  }

  /**
   * Returns if user has a given role.
   * @param role role
   */
  userHasRole(role: string) {
    return this.userHasAnyRole([role]);
  }

  /**
   * Returns whether user is a sales user.
   */
  userHasSalesUserRole() {
    return this.userHasRole('SALES_USER');
  }

  /**
   * Returns whether user is a review user.
   */
  userHasReviewUserRole() {
    return this.userHasRole('REVIEW_USER');
  }

  /**
   * Returns whether user is a routing user.
   */
  userHasRoutingUserRole() {
    return this.userHasRole('ROUTING_USER');
  }

  /**
   * Returns whether user is an approval user.
   */
  userHasApprovalUserRole() {
    return this.userHasRole('APPROVAL_USER');
  }

  /**
   * Returns whether user is a finish calculation user.
   */
  userHasFinishCalculationUserRole() {
    return this.userHasRole('FINISH_CALCULATION_USER');
  }

  /**
   * Returns whether user has role that is related to quotation.
   */
  userHasAnyQuotationRole() {
    return this.userHasReviewUserRole() || this.userHasRoutingUserRole() || this.userHasApprovalUserRole() || this.userHasFinishCalculationUserRole();
  }
}
