<footer class="d-flex flex-row justify-content-between align-items-center font-size-s pt-5 pb-4">
  <span class="text-kxlightgrey">2021 Kamax powered by Nexineer Digital GmbH</span>

  <a href="https://www.nexineer.io/" target="_blank">
    <img src="assets/nexineer-logo.svg" alt="nexineer digital GmbH" width="90" height="32">
  </a>

  <nav>
    <ul>
      <li><a href="https://www.kamax.com/en/terms-and-conditions-of-business/" target="_blank" translate>footer_conditions</a></li>
      <li><a href="https://www.kamax.com/en/data-privacy/" target="_blank" translate>footer_data_protection</a></li>
      <li><a href="https://www.kamax.com/en/imprint/" target="_blank" translate>footer_company_details</a></li>
      <li><a href="https://www.kamax.com/en/" target="_blank" translate>Kamax</a></li>
    </ul>
  </nav>

</footer>
