import { Component } from '@angular/core';
import { SupportedLanguagesService } from '../../modules/shared/services/supported-languages-service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-language-switcher',
  templateUrl: './language-switcher.component.html',
  styleUrls: ['./language-switcher.component.scss']
})
export class LanguageSwitcherComponent {
  supportedLanguages$ = this.supportedLanguagesService.getAllSupportedLanguages();

  constructor(
    private supportedLanguagesService: SupportedLanguagesService,
    private translateService: TranslateService
  ) { }

  changeLanguage(languageCode: string): void {
    this.translateService.use(languageCode);
  }

  isCurrentLanguage(languageCode: string): boolean {
    return languageCode.toUpperCase() === this.translateService.currentLang?.toUpperCase();
  }
}
