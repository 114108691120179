<header class="d-flex justify-content-between align-items-center py-5">

  <a href="https://www.kamax.com/"><img src="assets/kamax_logo.svg" alt="Kamax" width="74" height="32" /></a>

  <div class="text-uppercase font-weight-bold text-kxdarkgrey" translate>app_title</div>

  <div class="d-flex justify-content-between align-items-center">
    <app-language-switcher></app-language-switcher>
    <button class="btn btn-link p-0 align-items-center ml-4" (click)="authService.logout()">
      <img src="assets/icons/sign_out-24px.svg" width="24" height="24" class="mr-1" />
      <span class="font-size-s" translate>button_sign_out</span>
    </button>
  </div>
</header>
