<div class="p-4">
  <div class="d-flex flex-row justify-content-between align-items-center">
    <div [ngSwitch]="status" class="d-flex flex-row align-items-center font-size-s">
      <ng-container *ngSwitchCase="'UPLOADING'">
        <span class="inline-loader mr-3"></span>
        <span translate>files_uploading</span>
      </ng-container>
      <ng-container *ngSwitchCase="'PROCESSING'">
        <span translate>files_in_process</span>
      </ng-container>
      <ng-container *ngSwitchCase="'POSTPROCESSING'">
        <span translate>files_in_postprocess</span>
      </ng-container>
      <ng-container *ngSwitchCase="'COMPLETED'">
        <span translate>files_processed</span>
      </ng-container>
    </div>
    <!--button *ngIf="status !== 'COMPLETED'" (click)="abortAnalysis()" type="button" class="btn btn-link p-0">
      <img src="assets/icons/close-24px.svg" width="24px" height="24px" />
    </button-->
  </div>

  <div *ngIf="status !== 'UPLOADING'" class="pt-3 pb-2">
    <div class="d-flex flex-row justify-content-between">
      <div class="font-size-xs text-kxdarkblue mb-2" style="height: 24px">
        {{ analysisStatusResponse ? analysisStatusResponse.progress + '%' : '0%' }}
      </div>
      <img *ngIf="status === 'COMPLETED'" src="assets/icons/checkmark_teal.svg" width="24" height="24" />
    </div>
    <progressbar [value]="analysisStatusResponse?.progress" type="info"></progressbar>
  </div>
</div>

