import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { AlertService } from './alert.service';
import { AuthService } from './auth.service';
import { Router} from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';

@Injectable()
export class CustomErrorHandler implements ErrorHandler {

  constructor(private injector: Injector) { }

  handleError(error: Error | HttpErrorResponse): void {
    const alertService = this.injector.get(AlertService);
    const authService = this.injector.get(AuthService);
    const router = this.injector.get(Router);
    const ngZone = this.injector.get(NgZone);
    const modalService = this.injector.get(BsModalService);

    if (error instanceof HttpErrorResponse) {
      // close all open modals
      for (let i = 1; i <= modalService.getModalsCount(); i++) {
        modalService.hide(i);
      }

      switch (error.status) {
        case 0:
          authService.deleteTokens();
          ngZone.run(() => router.navigate(['/backend-not-available']));
          break;

        case 401:
          authService.deleteTokens();
          ngZone.run(() => router.navigate(['/login'])).then(() => {
            alertService.error('error_unauthorized');
          });
          break;

        case 403:
          alertService.error('error_forbidden');
          break;

        case 500:
          alertService.error('internal_server_error');
          break;
      }
    }

    console.error(error);
  }
}
