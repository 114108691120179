import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccessDeniedComponent } from './components/access-denied/access-denied.component';
import { AnalyzerComponent } from './components/analyzer/analyzer.component';
import { BackendNotAvailableComponent } from './components/backend-not-available/backend-not-available.component';
import { LoginComponent } from './components/login/login.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { StyleguideElementsComponent } from './components/styleguide-elements/styleguide-elements.component';
import { AuthGuard } from "./guards/auth.guard";

const routes: Routes = [
  {
    path: 'backend-not-available',
    component: BackendNotAvailableComponent},
  {
    path: 'access-denied',
    component: AccessDeniedComponent
  },
  { 
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'styleguide',
    component: StyleguideElementsComponent,
    data:{ caption:'style_elements' }
  },
  {
    path: 'analyzer',
    component: AnalyzerComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'page-not-found',
    component: PageNotFoundComponent
  },

  // Default: redirect to analyzer component
  {path: '**', redirectTo: 'analyzer'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
