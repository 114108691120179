import { Component, ViewChild } from '@angular/core';
import { AuthService } from './services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  @ViewChild('screen', { static: true }) screen: any;

  showFeedbackButton = false;

  constructor(
    public translateService: TranslateService,
    public authService: AuthService,
    public router: Router,
    public route: ActivatedRoute) {
      translateService.setDefaultLang('en');
      translateService.use('en');

      this.showFeedbackButton = environment.showFeedbackButton;
  }

  provideFeedback(): void {
    const subject = 'Feedback Automatised Price Adaption Service';
    const body =
    'Please provide a detailed description for your feedback and add a screenshot:\n\n\n\n' +
    'User: ' + localStorage.getItem('username') + '\n' +
    'URL: ' + window.location.href + '\n' +
    'Date: ' + new Date();

    const linkElement = document.createElement('a');
    linkElement.href = 'mailto:' + environment.feedbackEmail + '?cc=' + environment.feedbackEmailCC + '&subject=' + encodeURIComponent(subject) + '&body=' + encodeURIComponent(body);
    const event = new MouseEvent('click'); // click() does no work in firefox
    linkElement.dispatchEvent(event);
  }
}
