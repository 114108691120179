import {Component, EventEmitter, OnInit, Output, Input} from '@angular/core';
import {AuthService} from "../../services/auth.service";
import {Router, ActivatedRoute, ParamMap} from "@angular/router";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AlertService } from 'src/app/services/alert.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  redirectUrl = '/';
  isValidated = false;

  @Output() loggedIn = new EventEmitter<boolean>();

  constructor(private formBuilder: FormBuilder, private authService: AuthService, private alertService: AlertService,
    private router: Router, private route: ActivatedRoute) {

    this.route.queryParamMap.subscribe((result:ParamMap) => {
      if(result.get('redirectUrl')) {
        this.redirectUrl = result.get('redirectUrl');
      }
    });
  }

  ngOnInit() {
    // Logout if logged in
    if (this.authService.isLoggedIn()) {
      this.authService.logout();
    }

    // Build form
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  onLogin() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.authService.login(this.f.email.value, this.f.password.value).subscribe(res => {
        this.router.navigateByUrl(this.redirectUrl);
        this.loading = false;
      },
      errorResult => {
        if(errorResult.error.error === "invalid_grant") {
          this.alertService.error('error_invalid_grant');
          this.isValidated = true;
        } else {
          this.alertService.error(errorResult.error.errorId);
        }
      });
  }

}
