<div class="container">
  <app-header></app-header>

  <main class="bg-white shadow-sm py-4 px-5 rounded">
    <div class="mb-4">
      <h2 translate>add_files</h2>

      <ngx-dropzone id="dropzone" [accept]="acceptedMimeTypes" [maxFileSize]="maxFileSize" (change)="onDrop($event)">
        <ngx-dropzone-label class="text-left m-0 ml-4 text-kxdarkgrey">
          <img src="assets/icons/upload_30px.svg" width="30" height="30" class="mr-3">
          <span [innerHtml]="'drop_files_here' | translate"></span>
        </ngx-dropzone-label>
      </ngx-dropzone>

      <div class="d-flex flex-row justify-content-between py-2 font-size-s text-kxlightgrey">
        <div translate>files_supported</div>
        <div translate>maximum_size</div>
      </div>
    </div>

    <apa-alert></apa-alert>

    <div *ngIf="files?.length > 0" class="my-5">
      <h2 translate>added_files</h2>

      <div *ngFor="let file of files; let i = index" class="d-flex flex-row justify-content-between align-items-center py-3 border-bottom">
        <div class="d-flex flex-row align-items-center">
          <img src="assets/icons/file_30px.svg" width="30" height="30" />
          <div class="pl-3">
            <div class="font-size-s">
              {{ file.name }}
            </div>
            <div class="font-size-xs text-kxlightgrey">
              {{ file.size / 1000000 | number: '1.1-1' }} MB
            </div>
          </div>
        </div>

        <button *ngIf="!done" (click)="onRemove(i)" class="btn btn-link p-0">
          <img src="assets/icons/delete_24px.svg" alt="Delete" width="24" height="24" />
        </button>

        <img *ngIf="done" src="assets/icons/checkmark_teal.svg" width="24" height="24" />
      </div>
    </div>

    <div *ngIf="done" class="py-3 px-4 mb-3 bg-background d-flex flex-row align-items-center rounded">
      <img src="assets/icons/file_30px.svg" width="30" height="30" />
      <div class="pl-3 pr-5 font-size-s">
        {{resultAnalysisFilename}}
      </div>
      <div class="d-flex align-items-center">
        <button (click)="downloadAnalysisResultFile()" class="btn btn-sm btn-primary" style="min-width: 120px" [disabled]="isDownloadingResultAnalysisFile" translate>
          button_download
        </button>
        <div class="inline-loader ml-3 my-auto" *ngIf="isDownloadingResultAnalysisFile"></div>
      </div>
    </div>

    <div *ngIf="done" class="py-3 px-4 mb-5 bg-background d-flex flex-row align-items-center rounded">
      <img src="assets/icons/file_30px.svg" width="30" height="30" />
      <div class="pl-3 pr-5 font-size-s">
        {{resultTransformedDocumentsFilename}}
      </div>
      <div class="d-flex align-items-center">
        <button (click)="downloadTransformedDocumentsFile()" class="btn btn-sm btn-primary" style="min-width: 120px" [disabled]="isDownloadingTransformedDocumentsFile" translate>
          button_download
        </button>
        <div class="inline-loader ml-3 my-auto" *ngIf="isDownloadingTransformedDocumentsFile"></div>
      </div>
    </div>

    <button *ngIf="!done" (click)="startAnalysis()" [disabled]="files?.length === 0"
            class="btn btn-primary" style="min-width: 140px" translate>
      button_start
    </button>

    <button *ngIf="done" (click)="startNewCalculation()" class="btn btn-primary" style="min-width: 140px" translate>
      button_start_new_calculation
    </button>
  </main>

  <app-footer></app-footer>
</div>
