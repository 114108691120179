import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AnalyzerComponent } from './components/analyzer/analyzer.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxPaginationModule } from 'ngx-pagination';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { LocalizationLoader } from './localizationLoader';
import { AuthGuard } from './guards/auth.guard';
import { AuthService } from './services/auth.service';
import { StyleguideElementsComponent } from './components/styleguide-elements/styleguide-elements.component';
import { LoginComponent } from './components/login/login.component';
import { AlertComponent } from './components/alert/alert.component';
import { BackendNotAvailableComponent } from './components/backend-not-available/backend-not-available.component';
import { AccessDeniedComponent } from './components/access-denied/access-denied.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './components/header/header.component';
import { LanguageSwitcherComponent } from './components/language-switcher/language-switcher.component';
import { FooterComponent } from './components/footer/footer.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AnalyzerModalComponent } from './components/analyzer-modal/analyzer-modal.component';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { CustomErrorHandler } from './services/custom-error-handler';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

export function HttpLoaderFactory(http: HttpClient) {
  return new LocalizationLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AlertComponent,
    AnalyzerComponent,
    BackendNotAvailableComponent,
    AccessDeniedComponent,
    PageNotFoundComponent,
    StyleguideElementsComponent,
    HeaderComponent,
    LanguageSwitcherComponent,
    FooterComponent,
    AnalyzerModalComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgSelectModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    NgxDropzoneModule,
    ModalModule.forRoot(),
    ProgressbarModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  entryComponents: [
    AnalyzerModalComponent
  ],
  providers: [
    AuthGuard,
    AuthService,
    {
      provide: ErrorHandler,
      useClass: CustomErrorHandler,
    },
    [ { provide: LocationStrategy,
        useClass: HashLocationStrategy
      }
    ]
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
