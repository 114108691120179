import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UtilService } from '../../modules/shared/services/util.service';
import { StartAnalysisRequestDto, StartAnalysisResponseDto, AnalysisStatusResponseDto } from '../../models/analysis';
import { AnalysisService } from '../../services/analysis.service';
import { AlertService } from '../../services/alert.service';
import { interval, Subscription } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';

export enum Status {
  UPLOADING = 'UPLOADING',
  PROCESSING = 'PROCESSING',
  POSTPROCESSING = 'POSTPROCESSING',
  COMPLETED = 'COMPLETED',
}

@Component({
  selector: 'app-analyzer-modal',
  templateUrl: './analyzer-modal.component.html',
  styleUrls: ['./analyzer-modal.component.scss']
})
export class AnalyzerModalComponent implements OnInit, OnDestroy {

  files: File[];
  status: Status;
  startAnalysisResponse: StartAnalysisResponseDto;
  analysisStatusResponse: AnalysisStatusResponseDto;
  analysisStatusPollingSubscription: Subscription;

  constructor(
    public bsModalRef: BsModalRef,
    private utilService: UtilService,
    private analysisService: AnalysisService,
    private alertService: AlertService
  ) { }

  async ngOnInit(): Promise<void> {
    await this.startUpload();
  }

  ngOnDestroy(): void {
    this.stopPollingAnalysisStatus();
  }

  async startUpload(): Promise<void> {
    this.status = Status.UPLOADING;
    this.analysisService.startAnalysis(await this.encodeAnalysisRequestDto()).subscribe(analysisResponse => {
      this.startAnalysisResponse = analysisResponse;

      if (analysisResponse.errorMessage !== null) {
        this.alertService.error(analysisResponse.errorMessage);
      }

      if (analysisResponse.jobId !== null) {
        this.startPollingAnalysisStatus(analysisResponse.jobId);

      } else {
        this.bsModalRef.hide();
      }
    });
  }

  startPollingAnalysisStatus(jobId: string): void {
    this.status = Status.PROCESSING;

    this.analysisStatusPollingSubscription = interval(500)
      .pipe(
        startWith(0),
        switchMap(() => this.analysisService.getAnalysisStatus(jobId)))
      .subscribe(analysisStatus => {
        this.analysisStatusResponse = analysisStatus;
        if (this.analysisStatusResponse.progress === 100) {
          this.status = Status.POSTPROCESSING;
        }
        if (this.analysisStatusResponse.done) {
          this.stopPollingAnalysisStatus();
          this.status = Status.COMPLETED;
          setInterval(() => this.bsModalRef.hide(), 1500);
        }
      });
  }

  stopPollingAnalysisStatus(): void {
    if (this.analysisStatusPollingSubscription) {
      this.analysisStatusPollingSubscription.unsubscribe();
    }
  }

  async encodeAnalysisRequestDto(): Promise<StartAnalysisRequestDto> {
    return {
      files: await Promise.all(this.files.map(async file => ({
        filename: file.name,
        content: await this.utilService.getBase64FromFile(file)
      })))
    };
  }

  abortAnalysis(): void {
    this.stopPollingAnalysisStatus();
    this.bsModalRef.hide();
  }
}
