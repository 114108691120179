import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor() { }

  /**
   * Creates download link from base64 encoded file and dispatches a click event on it.
   * @param base64string  base64 encoded file
   * @param mimeType      filetype without trailing slash
   * @param fileName      name of downloaded file
   */
  downloadFileFromBase64String(base64string: string, mimeType: string, fileName: string): void {
    const linkSource = `data:${mimeType};base64,${base64string}`;
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    const event = new MouseEvent('click'); // click() does no work in firefox
    downloadLink.dispatchEvent(event);
  }

  /**
   * Converts a file to a base64 string.
   * @param file file to encode
   * @return promise to return base64 string
   */
  getBase64FromFile(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve((reader.result as string).split(',')[1]);
      };
      reader.onerror = error => reject(error);
    });
  }
}
